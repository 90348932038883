th.rotated-text {
  height: 95px;
  white-space: nowrap;
  padding: 0;
}

th.rotated-text > div {
  transform: rotate(270deg);
  width: 30px;
}

th.rotated-text > div > span {
  padding: 5px 10px;
}
