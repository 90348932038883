/* The Modal (background) */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, .9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  display: block;
  width: 80%;
  max-width: 700px;
  margin: auto;

  /* Add Animation */
  animation-name: zoom;
  animation-duration: .6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 40px;
  font-weight: 700;
  color: #f1f1f1;
  transition: .3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
