$img-format: 166px !default;

.thumbnail-selected {
  border: 2px solid $success;
}

.thumbnail-edited {
  border: 2px solid $warning;
}

.media-actions {
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: rgba(255, 255, 255, .9);
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
}

.media-btn {
  cursor: default !important;
}

.media-icon {
  font-size: $img-format;
  line-height: .8;
}

.media-icon-small {
  display: inline-flex;
  font-size: 16px;
}
