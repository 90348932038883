// fix badge
.grecaptcha-badge {
  display: none;
}

.mw-800 {
  max-width: 800px;
}

@media print {
  .page-break {
    page-break-before: always;
  }
}
